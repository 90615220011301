import React from 'react'
import Container from "@material-ui/core/Container";
import GatsbyImage from "gatsby-image";
import {graphql} from "gatsby";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import components from "../components/mdx_components";
import {MDXRenderer} from "gatsby-plugin-mdx";
import {MDXProvider} from "@mdx-js/react";
import SEO from "../components/seo";
import VideoCard from "../components/VideoCard";
import EventsCard from "../components/EventsCard";

const Page = ({data}) => {
  const events = data.events.nodes.filter(x => new Date(x.frontmatter.date) > new Date())

  return (
    <React.Fragment>
      <SEO title="TecknoYoga"/>
      <Container maxWidth={"md"}>
        <Box my={1}>
          <Card>
            <GatsbyImage fluid={data.file.childImageSharp.fluid}/>
            <Box m={1}>
              <MDXProvider components={components}><MDXRenderer>{data.shiatsu.body}</MDXRenderer></MDXProvider>
            </Box>
          </Card>
        </Box>
        {events.length > 0 ? (
          <Box my={1}>
            <EventsCard events={events}/>
          </Box>
        ) : null}
        <Box my={1}>
          <VideoCard videoId={data.video.frontmatter.videoId} caption={data.video.frontmatter.caption}/>
        </Box>
      </Container>
    </React.Fragment>
  )
}

export default Page

export const pageQuery = graphql`
    query {
        shiatsu: mdx(frontmatter: { title: { eq: "TecknoYoga" } }) {
            body
        }
        video: mdx(frontmatter: { title: { eq: "vidShiatsu" } }) {
            frontmatter {
                videoId
                caption
            }
        }
        file(relativePath: { eq: "images/teckno_yoga_header.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 882, maxHeight: 500) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        events: allMdx(sort: {fields: frontmatter___date, order: ASC}, filter: {slug: {regex: "/events/"}, frontmatter: {topic: {eq: "tekno_yoga"}}}) {
            nodes {
                id
                body
                frontmatter {
                    date(formatString: "DD MMMM, YYYY")
                    time: date(formatString: "HH:mm")
                    title
                    location
                    price
                }
            }
        }
    }
`
